import { isEmpty } from './index'

export const rules = {
  required: value => !!value || parseInt(value) === 0 || 'Поле обязательно для заполнения',
  enumRequired: value => !!value || value === 0 || 'Поле обязательно для заполнения',
  passwordValidator: password => {
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
    const validPassword = regExp.test(password)
    return (
      validPassword ||
      'Поле должно содержать как минимум один символ в верхних и нижних регистрах, цифру и спецтальный симол и быть длиной в 8 символов'
    )
  },
  confirmedValidator: (value, target) => value === target || 'Пароль и подтвреждающий пароль не соответствуют друг другу',
  emailValidator: value => {
    if (isEmpty(value)) {
      return true
    }

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (Array.isArray(value)) {
      return value.every(val => re.test(String(val)))
    }

    return re.test(String(value)) || 'Некорректный Email'
  },
  urlValidator: value => {
    if (value === undefined || value === null || value.length === 0) {
      return true
    }
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

    return re.test(value) || 'Неправильная ссылка'
  },
  integerValidator: value => {
    if (isEmpty(value)) {
      return true
    }

    if (Array.isArray(value)) {
      return value.every(val => /^-?[0-9]+$/.test(String(val)))
    }

    return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer'
  },
}
