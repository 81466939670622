<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form :lazy-validation="true" class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="host"
                label="Адрес"
                dense
                hide-details="auto"
                hint="Локальный ардес расположения API CRM"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="port"
                label="Порт"
                dense
                hide-details="auto"
                hint="Порт, на котором работает API CRM"
                outlined
                :rules="[rules.required, rules.integerValidator]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="globalType"
                label="GlobalType"
                dense
                hide-details="auto"
                outlined
                hint="Используется для запросов к CRM"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="terminalType"
                label="Тип терминала"
                dense
                hide-details="auto"
                hint="Используется для запросов к CRM"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="defaultCountryId"
                label="Идентификатор страны"
                dense
                hide-details="auto"
                hint="Идентификатор страны по умолчанию из Crm"
                outlined
                :rules="[rules.required, rules.integerValidator]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="sqlServerDataBaseConnectionString"
                label="SQL Server"
                dense
                hide-details="auto"
                hint="строка подключения к базе данных SQL Server Crm"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>


            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'CrmSettingsForm',
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('crmSettings/form', [
      'port',
      'host',
      'terminalType',
      'globalType',
      'defaultCountryId',
      'sqlServerDataBaseConnectionString',
    ]),
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('crmSettings/form', ['resetForm']),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
