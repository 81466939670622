<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6" ref="form">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="plaintext"
              label="Ключ"
              dense
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="partLength"
              label="Длина части кода"
              dense
              outlined
              :rules="[rules.required, rules.integerValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="parts"
              label="Количество частей"
              dense
              outlined
              :rules="[rules.required, rules.integerValidator]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              @click="saveData"
              color="primary"
              class="me-3 mt-4"
            >
              Сохранить
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click="cancel"
            >
              Отмена
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'ReferralCodeSettingForm',
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('referralCodeSettings', [
      'parts',
      'partLength',
      'plaintext'
    ]),
  },
  async created() {
    await this.fetch()
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    ...mapActions('referralCodeSettings', ['update', 'fetch']),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.update()
      }
    },
  },
}
</script>
