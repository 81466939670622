<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiFood }}
        </v-icon>
        <span>Rk7</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiClipboardAccount }}
        </v-icon>
        <span>CRM</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiMessage }}
        </v-icon>
        <span>SMS</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiAccountSettings }}
        </v-icon>
        <span>Client Settings</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiQrcode }}
        </v-icon>
        <span>QR Code</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiNaturePeople }}
        </v-icon>
        <span>Referral Code</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiCard }}
        </v-icon>
        <span>Pay Box</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiBookCancel }}
        </v-icon>
        <span>Отмена заказа</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <rk7-settings-list></rk7-settings-list>
      </v-tab-item>
      <v-tab-item>
        <crm-settings-list></crm-settings-list>
      </v-tab-item>
      <v-tab-item>
        <sms-settings-form></sms-settings-form>
      </v-tab-item>
      <v-tab-item>
        <crm-client-settings-form></crm-client-settings-form>
      </v-tab-item>
      <v-tab-item>
        <qr-code-settings-form></qr-code-settings-form>
      </v-tab-item>
      <v-tab-item>
        <referral-code-setting-form></referral-code-setting-form>
      </v-tab-item>
      <v-tab-item>
        <pay-box-settings-form></pay-box-settings-form>
      </v-tab-item>
      <v-tab-item>
        <order-cancellation-setting-form></order-cancellation-setting-form>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiClipboardAccount,
  mdiFood,
  mdiMessage,
  mdiCard,
  mdiAccountSettings,
  mdiQrcode,
  mdiNaturePeople,
  mdiBookCancel,
} from '@mdi/js'

import Rk7SettingsList from './rk7-setting-list.vue'
import CrmSettingsList from './crm-settings-list.vue'
import SmsSettingsForm from './sms-settings-form.vue'
import PayBoxSettingsForm from './pay-box-setting-form.vue'
import CrmClientSettingsForm from './crm-client-setting-form.vue'
import QrCodeSettingsForm from './qr-code-setting-form.vue'
import ReferralCodeSettingForm from '@/views/system-settings/referral-code-setting-form.vue'
import OrderCancellationSettingForm from '@/views/system-settings/order-cancellation-settings.vue'

export default {
  name: 'SystemSettings',
  components: {
    ReferralCodeSettingForm,
    Rk7SettingsList,
    CrmSettingsList,
    PayBoxSettingsForm,
    SmsSettingsForm,
    CrmClientSettingsForm,
    QrCodeSettingsForm,
    OrderCancellationSettingForm,
  },
  data: () => ({
    icons: {
      mdiFood,
      mdiClipboardAccount,
      mdiMessage,
      mdiBookCancel,
      mdiNaturePeople,
      mdiCard,
      mdiAccountSettings,
      mdiQrcode,
    },
    tab: null,
  }),
  async mounted() {
  },
}
</script>
