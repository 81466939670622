<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6" ref="form">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="contactTypeId"
              :items="crmContactTypesDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Тип контакта"
              dense
              :rules="[rules.required]"
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="groupId"
              :items="crmClientGroupsDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Группа"
              dense
              :rules="[rules.required]"
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="moneySpentAccountTypeId"
              :items="crmAccountTypesDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Потраченные деньги"
              dense
              :rules="[rules.required]"
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="bonusAmountAccountTypeId"
              :items="crmAccountTypesDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Баллы"
              dense
              :rules="[rules.required]"
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="discountDataAccountTypeId"
              :items="crmAccountTypesDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Дисконтный счет"
              dense
              :rules="[rules.required]"
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="orderAmountAccountTypeId"
              :items="crmAccountTypesDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Количество заказов"
              dense
              :rules="[rules.required]"
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn
              @click="saveData"
              color="primary"
              class="me-3 mt-4"
            >
              Сохранить
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click="cancel"
            >
              Отмена
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'CrmClientSettingsForm',
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('crmClientSettings', [
      'groupId',
      'contactTypeId',
      'moneySpentAccountTypeId',
      'bonusAmountAccountTypeId',
      'discountDataAccountTypeId',
      'orderAmountAccountTypeId'
    ]),
    ...mapGetters('dictionary', {
      crmClientGroupsDropDown: 'crmClientGroupsDropDown',
      crmContactTypesDropDown: 'crmContactTypesDropDown',
      crmAccountTypesDropDown : 'crmAccountTypesDropDown'
    }),
  },
  async created() {
    await this.fetch()
    await this.fetchCrmClientGroups()
    await this.fetchCrmContactTypes()
    await this.fetchCrmAccountTypes()
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    ...mapActions('crmClientSettings', ['update', 'fetch']),
    ...mapActions('dictionary', ['fetchCrmClientGroups', 'fetchCrmContactTypes', 'fetchCrmAccountTypes']),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.update()
      }
    },
  },
}
</script>
