<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6" ref="form">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="url"
              label="Ссылка"
              dense
              outlined
              :rules="[rules.required, rules.urlValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="merchantId"
              label="Идентификатор магазина"
              dense
              outlined
              :rules="[rules.required, rules.integerValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="merchantSecret"
              label="Секретный ключ для приема платежей"
              dense
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="secretPayout"
              label="Секретный ключ для выплат клиентам"
              dense
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="addCardCallBackUrl"
              label="Ссылка на call back добавления карты"
              dense
              outlined
              :rules="[rules.required, rules.urlValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="addCardSuccessUrl"
              label="Ссылка на успешное добавление карты"
              dense
              outlined
              :rules="[rules.required, rules.urlValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="createOrderCallBackUrl"
              label="Ссылка на call back оплаты заказа"
              dense
              outlined
              :rules="[rules.required, rules.urlValidator]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="createMobileOrderSuccessUrl"
              label="Ссылка на успешный результат оплаты с мобильного приложения"
              dense
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="createMobileOrderFailureUrl"
              label="Ссылка на неуспешный результат оплаты с мобильного приложения"
              dense
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="createWebOrderSuccessUrl"
              label="Ссылка на успешный результат оплаты с WEB сайта"
              dense
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="createWebOrderFailureUrl"
              label="Ссылка на неуспешный результат оплаты с WEB сайта"
              dense
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              @click="saveData"
              color="primary"
              class="me-3 mt-4"
            >
              Сохранить
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click="cancel"
            >
              Отмена
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { rules } from '@/helpers/validation'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions } from 'vuex'

export default {
  name: 'PayBoxSettingForm',
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('payBoxSettings', [
      'url',
      'merchantId',
      'merchantSecret',
      'secretPayout',
      'addCardCallBackUrl',
      'createOrderCallBackUrl',
      'createMobileOrderSuccessUrl',
      'createMobileOrderFailureUrl',
      'createWebOrderSuccessUrl',
      'createWebOrderFailureUrl',
      'addCardSuccessUrl'
    ]),
  },
  async created() {
    await this.fetch()
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    ...mapActions('payBoxSettings', ['update', 'fetch']),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.update()
      }
    },
  },
}
</script>

<style scoped>

</style>
