<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          @click="createDialogShow()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="rk7Settings"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <!-- name -->
      <template #[`item.host`]="{item}">
        {{ item.host }}
      </template>

      <template #[`item.path`]="{item}">
        {{ item.path }}
      </template>

      <template #[`item.userName`]="{item}">
        {{ item.userName }}
      </template>

      <template #[`item.password`]="{item}">
        {{ item.password }}
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <!-- edit -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="updateDialogShow(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <rk7-setting-form :label="'Редактирование настроек Rk7'" :cancellation="updateDialogCancel" :show="updateDialog"
                      :save="updateApplicationDescription"></rk7-setting-form>
    <rk7-setting-form :label="'Создание настроек Rk7'" :cancellation="createDialogCancel" :show="createDialog"
                      :save="addApplicationDescription"></rk7-setting-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import Rk7SettingForm from './rk7-setting-form'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'

export default {
  components: { Rk7SettingForm },
  name: 'Rk7SettingList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('rk7Settings', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('rk7Settings', {
      rk7Settings: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Адрес', value: 'host' },
        { text: 'Путь', value: 'path' },
        { text: 'Имя пользователя', value: 'userName' },
        { text: 'Пароль пользователя', value: 'password' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    this.fetchRk7Settings()
  },
  mounted() {
  },
  methods: {
    ...mapActions('rk7Settings', {
      fetchRk7Settings: 'fetchList',
    }),
    ...mapActions('rk7Settings/form', ['create', 'resetForm', 'fetchById', 'update']),
    async addApplicationDescription() {
      await this.create()
      this.createDialog = false
    },
    async updateApplicationDescription() {
      await this.update()
      this.updateDialog = false
    },
    createDialogShow() {
      this.createDialog = true
    },
    createDialogCancel() {
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
