<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="host"
                label="Адрес"
                dense
                hint="Адрес API RKeeper"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="path"
                label="Путь"
                dense
                hint="Путь к API RK7"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="userName"
                label="Имя пользователя"
                dense
                hint="Имя пользователя Rk7"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="password"
                label="Пароль пользователя"
                dense
                hint="Пароль пользователя rk7"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="sqlServerDataBaseConnectionString"
                label="SQL Server"
                dense
                hide-details="auto"
                hint="Строка подключения к базе данных SQL Server Rk7"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'Rk7SettingsForm',
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  computed: {
    ...mapFormHandlers('rk7Settings/form', [
      'host',
      'path',
      'userName',
      'password',
      'sqlServerDataBaseConnectionString',
    ]),
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('rk7Settings/form', ['resetForm']),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
